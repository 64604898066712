import {Button, Card, Dropdown, Header, InputField, Modal} from "Components";
import {useCart} from "Context/Cart";
import React, {useEffect, useState} from "react";
import "./Payment.scss";
import {formatCurrency} from "Utils/TextFormat";
import {useNavigate} from "react-router";
import {useAuth} from "Context/Auth";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Payment() {

	const cart = useCart();
	const navigate = useNavigate();
	const [paymentMethod,setPaymentMethod] = useState(null);
	const {t} = useTranslation();
	
	const userData = useAuth();

	const [userDetails,setUserDetails] = useState({
		fullName:'',
		email:'',
		phone:'',
		referrerId:''
	});
	const [billingAddress,setBillingAddress] = useState({
		street:"",
		city:"",
		state:"",
		country:"",
		zip:"",
		_id:"",
		isNew: true
	});
	const [refCode,setRefCode] = useState(null);
	const [refCodeValidated,setRefCodeValidated] = useState(false);

	useEffect(()=>{
		if(userData.id || userData.uuid){
			setUserDetails(userData);
		}
	},[userData]);

	useEffect(()=>{
		setRefCode(localStorage.getItem("storeRefCode"));
		if(!userData?.referrerId){
			setUserDetails({
				...userDetails, referrerId: localStorage.getItem("storeRefCode")
			})
		}
	},[]);

	useEffect(()=>{
		if(userDetails.referrerId?.length >= 1){
			setRefCodeValidated(false);
		}else{
			setRefCodeValidated(true);
		}
	},[userDetails.referrerId])

	const handleChange = (e) => {
		setUserDetails({...userDetails,[e.target.name]:e.target.value});
	}

	const handlePayment = (e) => {
		e.preventDefault();

		// TODO - make this more elegant
		const billingAddressObj = billingAddress.isNew && !billingAddress._id ? {
			street: billingAddress.street,
			city: billingAddress.city,
			country: billingAddress.country,
			state: billingAddress.state,
			zip: billingAddress.zip,
		}:{
			id: billingAddress._id
		}

		axiosWithAuth().post("/store/orders", {
			products:Object.values(cart),
			userInformation: userDetails,
			paymentMethod,
			billingAddress: billingAddressObj
		}).then(res => {
			if(res.status === 201){
				navigate("/order/"+res.data.order.id)
			}
		}).catch(err => {
			if(err.response?.status === 504){
				alert(t("errors.notAvailableAtThisMoment"))
			}else{
				console.log(err);
				alert("errors.500")
			}
		})
	}

	const validateRefCode = () => {
		axiosWithAuth().post("/auth/validateRefcode",{code:userDetails.referrerId}).then(res=>{
			if(res.status === 200){
				setRefCodeValidated(true);
			}
		}).catch(err=>{
			if(err?.response?.status === 404){
				alert(t(`errors.codNotFound`))
				setRefCodeValidated(false);
			}else{
				console.log(err);
				alert("errors.500")
			}
		})
	}

	const allowPayment = () => {
		if(!paymentMethod){
			return true;
		}

		if(!userData.uuid && paymentMethod==="Wallet"){
			return true;
		}

		if(userDetails.referrerId?.length >= 1 && !refCodeValidated){
			return true
		}
		
		return false;
	}

	if(Object.values(cart).length === 0){
		return (
		<div className="Checkout">
			{/* Nono */}
			<div className="wrapper">
				<p>{t("noProducts")}</p>
				<NavLink to="/products">
					{t("checkProductsHere")}
				</NavLink>
			</div>
		</div>
		)
	}

	return (
		<div className="Payment">
			<form onSubmit={handlePayment} className="wrapper">
				<Header 
					title="Facturacion" 
					alignText={"center"}
				/>
				
				<div className="leftPannel">
					<Card className={"forms"}>
						
						<div className="userDetails">
							<Header subtitle="Detalles cliente"/>
								<InputField
									label={t("name")}
									name="fullName"
									value={userDetails.fullName}
									onChange={handleChange}
									readOnly={userData?.uuid}
								/>
								
								<div className="row nowrap nores gp-1">
									<InputField
										label={t("email")}
										name="email"
										value={userDetails.email}
										onChange={handleChange}
										type="email"
										readOnly={userData?.uuid}
									/>
									<InputField
										label={t("phone")}
										name="phone"
										value={userDetails.phone}
										onChange={handleChange}
										type="phone"
										readOnly={userData?.phone}
									/>
								</div>
							
								<InputField
									// TODO remove this if not necessary label={`Codigo de referido${refCode===userd?" - (obtenido atraves de link de referido)":""}`}
									label={t("referrerCode")}
									name="referrerId"
									value={userDetails.referrerId}
									onChange={handleChange}
									autoComplete={false}
									slot={
										<Button
											theme="secondary"
											action={validateRefCode}
											disabled={userDetails.referrerId?.length < 1 || refCodeValidated}
										>
											{t("validate")}
										</Button>
									}

								/>
						</div>

						<BillingAddressForm
							billingAddress={billingAddress}
							setBillingAddress={setBillingAddress}
						/>

					</Card>

				</div>
				

				<div className="rightPannel">
					<Card.ProductsTotal
						products={Object.values(cart)}
					>
						<Button
							variant="outlined"
							theme="secondary"
							action={() => { navigate("/products")}}
						>
							{t("buyMore")}
						</Button>
					</Card.ProductsTotal>

					<Card className="paymentMethods">
			
			
						<Dropdown title={paymentMethod || "Metodos de pago"}>
							<Dropdown.Item
								action={() => {setPaymentMethod("Wallet")}}
								selected={paymentMethod==="Wallet"}
							>
								Wallet
							</Dropdown.Item>

							<Dropdown.Item
								action={() => {setPaymentMethod("Stripe")}}
								selected={paymentMethod==="Stripe"}
							>
								Stripe
							</Dropdown.Item>

						</Dropdown>
			
						<Button 
							className="payNow"
							theme="secondary"
							type="submit"
							disabled={allowPayment()}	
						>
							{t("payNow")}
						</Button>

					</Card>

					<p className="disclaimer">
						{t("paymentDisclaimer")}
					</p>

				</div>
			</form>
		</div>
	);
}

export default Payment;

const BillingAddressForm = ({billingAddress,setBillingAddress}) => {	
	const userData = useAuth();
	const [billingAddresses,setBillingAddresses] = useState([]);
	const [selectedBillingAddress,setSelectedBillingAddress] = useState(null);
	const [newBA,setNewBa] = useState(false);
	const [showBAModal,setShowBAModal] = useState(false);
	const {t} = useTranslation();

	useEffect(()=>{
		fetchUserBillingAddress();
	},[userData]);

	useEffect(()=>{
		if(billingAddresses.length > 0){
			// TODO Loop and find default BA.
			setSelectedBillingAddress(billingAddresses[0]);
		}
	},[billingAddresses]);

	useEffect(()=>{
		if(selectedBillingAddress?._id){
			setBillingAddress({
				_id: selectedBillingAddress._id,
				city:'',
				street:'',
				state:'',
				country:'',
				zip:'',
				isNew: false
			})
		}
	},[selectedBillingAddress]);

	const fetchUserBillingAddress = () => {
		if(!userData.id){
			return null;
		}
		// TODO
		// axiosWithAuth().get("/store/billingAddress/"+userData.id).then(res => {
		// 	setBillingAddresses(res.data);
		// }).catch(err => {
		// 	console.log(err);
		// })
	}

	const handleBillingAddress = (e) => {
		setBillingAddress({
			...billingAddress,
			isNew:true,
			id:null,
			__v:null,
			_id:null,
			[e.target.name]:e.target.value
		});
	}

	if(billingAddresses.length > 0 && !newBA){
		return <div className="billingAddresses">
			<Header subtitle={t("billingAddress")}/>

			<div className="row options gp-1">
				{/* This should show the primary one */}
				<div className="clickable-wrapper" onClick={() => {setShowBAModal(true)}}>
					<Card.BillingAddress 
						billingAddress={selectedBillingAddress || billingAddresses[0]} 
						noControls
					/>
				</div>

				<Card.Secondary 
					centered 
					className="newAddress"
					action={() => {setNewBa(true)}}
				>
					{t("Añadir")}
				</Card.Secondary>
			</div>


			{showBAModal?
				<SelectBillingAddressModal
					billingAddresses={billingAddresses}
					selectedBillingAddress={selectedBillingAddress}
					selectBillingAddress={setSelectedBillingAddress}
					fetchUserBillingAddress={fetchUserBillingAddress}
					closeModal={() => {setShowBAModal(false)}}
				/>
			:null}

		</div>;
	}else{
		return (<div className="billingAddresses">
			<Header subtitle={t("billingAddress")}/>
			<InputField
				label={t("address")}
				value={billingAddress.street}
				onChange={handleBillingAddress}
				name="street"
				autoComplete={"street-address"}
				required
			/>
			<div className="row nowrap nores gp-1">	
				<InputField
					label={t("city")}
					value={billingAddress.city}
					onChange={handleBillingAddress}
					name="city"
					required
				/>
				<InputField
					label={t("state")}
					value={billingAddress.state}
					onChange={handleBillingAddress}
					name="state"
					required
				/>
			</div>

			<div className="row nowrap nores gp-1">
				<InputField
					label={t("country")}
					value={billingAddress.country}
					onChange={handleBillingAddress}
					name="country"
					required
				/>
				<InputField
					label={t("zipCode")}
					value={billingAddress.zip}
					onChange={handleBillingAddress}
					name="zip"
					pattern="[0-9]*"
				/>
			</div>

			{newBA?
			
				<div className="row centered actions">

					<Button
						action={() => {
							setNewBa(false);
						}}
						theme="secondary"
					>{t("goBack")}</Button>
				</div>
			:null}

		</div>)
	}
}


const SelectBillingAddressModal = ({
	billingAddresses,
	fetchUserBillingAddress,
	selectedBillingAddress,
	selectBillingAddress,
	closeModal
}) => {

	const [editAddress,setEditAddress] = useState(false);
	const {t} = useTranslation();
	
	const editChangeHandler = (e) => {
		setEditAddress({...editAddress, [e.target.name]:e.target.value})
	}

	const handleAddressEdit = (editedAddress) => {
		if(!editedAddress._id){
			return null;
		}

		axiosWithAuth().patch(`/store/billingaddress/${editedAddress._id}`,editedAddress).then(res => {
			if(res.status === 200){
				setEditAddress(false);
				fetchUserBillingAddress();
			}
		}).catch(err => {
			if(err?.response?.status === 400){
				alert("Solicitud incorrecta. La información enviada es inválida o está incompleta.");
			}
			else if(err?.response?.status === 500){
				alert("Se produjo un error al crear la orden. Por favor, inténtalo de nuevo más tarde");
			}
			console.log(err);
		})
	}

	return (
		<Modal 
			className={"BillingAddress"}
			closeModal={closeModal}
			showCloseIcon
		>
			<Card>

				<div className="billingAddresses-wrapper">
					{billingAddresses.map(ba => <Card.BillingAddress
						billingAddress={ba} 
						setEditAddress={setEditAddress}
						selectBillingAddress={selectBillingAddress}
						selected={ba._id === selectedBillingAddress?._id}
					/>)}
				</div>

				<div className={classNames("editArea",{expanded:editAddress})}>
					<InputField
						label={t("address")}
						name="street"
						value={editAddress?.street}
						onChange={editChangeHandler}
					/>
					<InputField
						label={t("city")}
						name="city"
						value={editAddress?.city}
						onChange={editChangeHandler}
					/>
					<div className="row nowrap gp-1">
						<InputField
							label={t("state")}
							name="state"
							value={editAddress?.state}
							onChange={editChangeHandler}
						/>
						<InputField
							label={t("country")}
							name="country"
							value={editAddress?.country}
							onChange={editChangeHandler}
						/>
					</div>
					<InputField
						label={t("zipCode")}
						name="zip"
						value={editAddress?.zip}
						onChange={editChangeHandler}
					/>
					<div className="actions">

						<Button
							variant="outlined"
							theme="secondary"
							action={() => {setEditAddress(null)}}
						>
							{t("cancel")}
						</Button>

						{editAddress?
							<Button
								theme="secondary"
								action={() => {handleAddressEdit(editAddress)}}

							>
								{t("save")}
							</Button>
						:null}

					</div>
				</div>

			</Card>
		</Modal>
	)
}

