import React, {useEffect, useRef, useState} from "react";
import "./Dropdown.scss";
import {ArrowIcon} from "assets";
import classNames from "classnames";

const Dropdown = ({variant="primary",title,children,className="",keepOpenOnSelection=false}) => {
	
	const [isExpanded,setIsExpanded] = useState(false);
	const dropDownRef = useRef(null);

	useEffect(()=>{
		function handleMouseDown(event){
			if((dropDownRef?.current.contains(event.target) ||
				dropDownRef?.current.querySelector(".Dropdown-content-wrapper")?.contains(event.target))
			){
				return;
			}

			setIsExpanded(false);
		}

		document.addEventListener("mousedown", handleMouseDown);
		return () => { document.removeEventListener("mousedown", handleMouseDown) }
	},[dropDownRef]);

	const toggleDropDownMenu = (e) => {
		e.stopPropagation();
		setIsExpanded(!isExpanded);
	}

	const handleOptionClick = () => {
		if(!keepOpenOnSelection){
			setIsExpanded(false);
		}
	}

	return (
		<div className={classNames("Dropdown",variant,className,{expanded:isExpanded})} ref={dropDownRef}>
			
			<div className="Dropdown-button" onClick={toggleDropDownMenu}>
				<p>{title}</p> 
				<span className="icon">
					<ArrowIcon/>
				</span>
			</div>


			{isExpanded?
				<div className="Dropdown-content-wrapper">
					<div className="content">
						{/* {children} */}
						{React.Children.toArray(children).map(child => {
							return React.cloneElement(child, {...child.props, handleOptionClick});
						})}
					</div>
				</div>
			:null}
			
		</div>
	);
}

Dropdown.Item = ({children,action,className="",selected, handleOptionClick}) => {
	const onClickHandler = () => {
		handleOptionClick()
		action()
	}


	return (<div className={classNames("DropdownItem",className,{selected})} onClick={onClickHandler}>
		{children}
	</div>)
	// return (<div className={classNames("DropdownItem",className,{selected})} onClick={action}>
	// 	<p>{children}</p>
	// </div>)
}

export default Dropdown;