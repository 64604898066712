import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useState, createContext, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";

const AuthContext = createContext();
const AuthLoginContext = createContext();
const AuthLogoutContext = createContext();
const AuthInitialLoadContext = createContext();


export function useAuth() {
	return useContext(AuthContext)
}
export function useAuthLogin() {
	return useContext(AuthLoginContext)
}
export function useAuthLogout() {
	return useContext(AuthLogoutContext)
}
export function useInitalLoad() {
	return useContext(AuthInitialLoadContext)
}

export function AuthProvider ({children}){
	const [userData,setUserdata] = useState({}); // Logged user
	const [initialLoad,setInitialLoad] = useState(false);
	const {t} = useTranslation();

	useEffect(()=>{
		axiosWithAuth().get("users").then(res=>{
			setUserdata(res.data);
		}).catch(err=>{
			if(err.message){
				console.log(err);
			}
			if(err.response){
				console.log(err);
			}
		}).finally(()=>{
			setInitialLoad(true);
			if(process.env.REACT_APP_PLATFORM){
				window.document.title = process.env.REACT_APP_PLATFORM;
			}
		})
	},[])

	function login(data){
		axiosWithAuth().post("auth/login", data).then(res=>{
			console.log(res);
			setUserdata(res.data);
		}).catch(err => {
			if(err.response?.status === 404){
				// We return response bc we dont need to as we handle this case
				console.log(err);
				alert(t("Erorrs.notAvailableAtThisMoment"));
			}else if(err.response?.status === 401){
				alert("errors.401");
			}else{
				alert("errors.500");
			}
		})
	};

	function logout(){
		axiosWithAuth().post("auth/logout").then(res=>{
			window.localStorage.removeItem("cart");
			setUserdata(false);
		})
	}

	return (
		<AuthContext.Provider value={userData}>
			<AuthInitialLoadContext.Provider value={initialLoad}>
				<AuthLoginContext.Provider value={login}>
					<AuthLogoutContext.Provider value={logout}>
							{children}
					</AuthLogoutContext.Provider>
				</AuthLoginContext.Provider>
			</AuthInitialLoadContext.Provider>
		</AuthContext.Provider>
	)


}