import {useState, createContext, useContext, useEffect} from "react";
import {useAuth, useInitalLoad} from "./Auth"; // Import both useAuth and useInitalLoad

const CartContext = createContext();
const CartActionsContext = createContext();

export function useCart() {
	return useContext(CartContext)
}
export function useCartActions() {
	return useContext(CartActionsContext)
}

export function CartProvider ({children}){
	const [cart, setCart] = useState({});
	const [hasCartLoaded, setHasCartLoaded] = useState(false);
	const user = useAuth();
	const initialLoad = useInitalLoad();

	useEffect(() => {
		if(initialLoad && user !== undefined){
			loadCart();
		}
	}, [initialLoad, user]);

	const getCartKey = () => {
		return user && user.id ? `cart_${user.id}` : 'cart_guest';
	};

	const loadCart = () => {
		const cartKey = getCartKey();
		const savedCart = localStorage.getItem(cartKey);
		if (savedCart) {
			setCart(JSON.parse(savedCart));
		}
		setHasCartLoaded(true);
	};

	const saveCart = () => {
		if (!hasCartLoaded) return;
		const cartKey = getCartKey();
		window.localStorage.setItem(cartKey, JSON.stringify(cart));
	};

	useEffect(() => {
		if(initialLoad && hasCartLoaded){
			saveCart();
		}
	}, [cart, initialLoad, hasCartLoaded]);

	const addToCart = (item) => {
		if(cart[item.id]){
			setQuantity(item.id, cart[item.id].quantity + 1);
		} else {
			setCart({...cart, [item.id]: {...item, quantity: 1}});
		}
	}

	const setQuantity = (id, quantity) => {
		const newItem = {...cart[id], quantity: Number(quantity)};
		setCart({...cart, [id]: newItem});
	}

	const deleteItem = (id) => {
		const newCart = {...cart};
		delete newCart[id];
		setCart(newCart);
	}

	const getTotal = () => {
		return Object.values(cart).reduce((acc, curr) => 
			curr.price * curr.quantity + acc, 0
		)
	}

	return (
		<CartContext.Provider value={cart}>
			<CartActionsContext.Provider value={{
				addToCart,
				deleteItem,
				setQuantity,
				getTotal,
				loadCart
			}}>
				{children}
			</CartActionsContext.Provider>
		</CartContext.Provider>
	)
}